import {
	Button,
	CircularProgress,
	DrawerProps,
	FormControl,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Slider,
	Tooltip,
	Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Parser from "html-react-parser";
import MaxWhiteIcon from "../../assets/icons/MaxWhiteIcon";
import MagicWand from "../../assets/MagicWand.svg";
import React, { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { ReactCompareSlider, ReactCompareSliderHandle, styleFitContainer } from "react-compare-slider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useResizeObserver from "use-resize-observer";
import PremiumIcon from "../../assets/icons/Premium";
import { GENERAL_ORG_ID, MODIFIED_IMAGES_ORG_ID } from "../../Constants";
import { SubscriptionCreditsTypes } from "../../Constants/SubscriptionConstants";
import Context from "../../Context/Context";
import { IsIframe, sendPostMessage } from "../../Helpers/iframe";
import useErrorPopup from "../../hooks/useErrorPopup";
import { useEventListener } from "../../hooks/useEventListener";
import useOutsideClick from "../../hooks/useOutsideClick";
import Analytics, { ANALYTICS_EVENTS, HUBSPOT_EVENTS } from "../../Models/Analytics";
import { ToolConfigTabsTypes } from "../../Models/ToolsConfig";
import ImageError from "../../Pages/Tool/SearchForImage/Image/ImgeError";
import { CameraVideoActions } from "../../Pages/Tool/useImageEditor";
import BriaAPI from "../../sdk/resources/briaAPI";
import LoadingAnimation from "../Common/LoadingAnimation/LoadingAnimation";
import ShowAt from "../Common/Wrappers/ShowAt";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import FaceRect from "../UI/FaceRect";
import ObjectPoint from "../UI/ObjectPoint";
import CropImageTool from "./CropImgeTool/CropImageTool";
import FeedbackForm from "./FeedbackForm2";
import ShapeEditingImg from "./Testing/ShapeEditingImg";
import { TextOnImage } from "./TextOnImage";
import MinWhiteIcon from "../../assets/icons/MinWhiteIcon";

import {
	AnalysisStage,
	BriaObjectType,
	getImageMeta,
	SelectedPointInterface,
	ToolsBackGroundMode,
	uploadImageViaApi,
} from "./utilities";
import { IsGuestUser } from "../../Helpers/isGuest";
import BriaInfoBox from "../UI/BriaInfoBox";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";

import { ReactComponent as CompareButton } from "../../assets/svgs/compare-icon.svg";
import { ReactComponent as UndoIcon } from "../../assets/svgs/undo-icon.svg";
import { ReactComponent as RedoIcon } from "../../assets/svgs/redo-icon.svg";
import { ReactComponent as ResetIcon } from "../../assets/svgs/reset-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/svgs/arrow-dropdown.svg";
import useWindowSize from "../../hooks/useWindowSize";
import FollowCursorTooltip from "../UI/followCursorTooltip";
import ObjectRect from "../UI/ObjectRect";
import BriaDropdownButton from "../UI/BriaDropdownButton";
import RouterConstants from "../../Constants/RouterConstants";
import { addRecordToDownloads, consumeUserCredits } from "../../GraphQL/mutations";
import { captureException } from "@sentry/react";
import { startDownload } from "../../Helpers/images";
import { IframePostMessageTypes } from "../../Constants/IframeConstants";
import { SystemViewTypes } from "../../Models/SystemView";
import { uuid4 } from "@sentry/utils";

type ToolsImageProps = {
	objectsTooltipPosition: { x: number; y: number } | undefined;
	getProcessingOperation: () => string;
	isUncropApplied: boolean;
	textOnImage: string;
	disableDownloadButton: boolean;
	imageUrl: string;
	onObjectHover: (id: string) => void;
	clearObjectHover: () => void;
	objectOnHover: string;
	minZoom: number;
	maxZoom: number;
	imageVH: string;
	loading: boolean;
	proccessing?: boolean;
	videoUrl?: string | null;
	pointOfInterests: any[];
	drawerDirection?: DrawerProps["anchor"];
	imageRef: RefObject<HTMLImageElement>;
	selectedPoint: SelectedPointInterface | null;
	setSelectedPoint: (x: any) => void;
	generatingImageMetric: () => void;
	hasPointOfInterest?: boolean;
	fullscreen?: boolean;
	onUndo: () => void;
	onFullscreenChange: (value: boolean) => void;
	setImageScore: (value: string) => void;
	setPointOfInterests: (value: any[]) => void;
	resetPointsOfInterests: () => void;
	onChangeClickedIncreaseResolutionButton: (newClickedIncreaseResolutionButton: number | null) => Promise<void>;
	clickedIncreaseResButton: number | null;
	layersURL: string | null;
	openCreateVideoPopup: boolean;
	setOpenCreateVideoPopup: Dispatch<SetStateAction<boolean>>;
	openVideoReadyPopup: boolean;
	setOpenVideoReadyPopup: Dispatch<SetStateAction<boolean>>;
	backgroundString: ToolsBackGroundMode;
	originalImage: string;
	originalImageWithWatermark: string;
	isCompareMode: boolean;
	isImageMode: boolean;
	fetchCameraVideo: (actions: CameraVideoActions) => void;
	increaseResolution: (desiredIncrease: number) => void;
	cameraMotionVideo: string;
	cameraVideoStatus?: "Progress" | "Ready" | null;
	isCameraMovementTabSelected: boolean;
	isImageLoading: boolean;
	setIsImageLoading: (value: boolean) => void;
	doDownloadVideo: (url?: string) => void;
	isTextEditorTabSelected: boolean;
	isLogoTabSelected: boolean;
	isObjectsTabSelected: boolean;
	isObjectsManualBrushTabSelected: boolean;
	isObjectsAutomaticTabSelected: boolean;
	brushConfig: any;
	objectsTabCanvasRef: any;
	ChangeImageOnErrorOnLoad: () => void;
	croppedArea: any;
	cropImageFunction: (w: number, h: number, isCustom: boolean) => void;
	setActiveCropSizeBtn: (value: number) => void;
	activeCropSizeBtn: number;
	CropTabselected: boolean;
	cropZoom: number;
	setCropZoom: (value: number) => void;
	cropName?: string;
	originalImageDimensions: { width: number; height: number };
	setCroppedAreaPixels: (value: any) => void;
	croppedAreaPixels: any;
	setCroppedAreaPercentage: (value: any) => void;
	croppedAreaPercentage: any;
	setRotation: (value: number) => void;
	setDraftZoom: (value: number) => void;
	draftZoom: number;
	setCropName: (value: string | undefined) => void;
	rotation: number;
	hideImageUrlLoader?: boolean;
	isDownloadPsdLoading?: boolean;
	onRedo: () => void;
	canUndo: boolean;
	canRedo: boolean;
	canCompare: boolean;
	setIsCompareMode: (value: boolean) => void;
	onResetActions: () => void;
	draftRotation: number;
	draftCroppedAreaPercentage: any;
	draftCroppedAreaPixels: any;
	setDraftCroppedAreaPixels: (value: any) => void;
	setDraftCroppedAreaPercentage: (value: any) => void;
	setDraftRotation: (value: number) => void;
	setPreviewImage: (value: any) => void;
	previewImage: any;
	setCropImageUrl: (value: any) => void;
	cropImageUrl: any;
	setCroppedImage: (value: any) => void;
	croppedImage: any;
	zoomPersantage: number;
	naturalSize: any;
	cropWidth: number;
	cropHeight: number;
	setCropWidth: (value: number) => void;
	setCropHeight: (value: number) => void;
	setZoomPersantage: (value: number) => void;
	setNaturalSize: (value: any) => void;
	isUserHasIncreaseResolutionCredits: boolean;
	setIsUserHasIncreaseResolutionCredits: (value: boolean) => void;
	showIncreaseResolutionPopUp: boolean;
	setShowIncreaseResolutionPopUp: (value: any) => void;
	setImageOnLoadError: (value: any) => void;
	imageOnLoadError: boolean;
	setDownloadSaveLoading: (value: any) => void;
	createCroppedImage: (isPreview: boolean, passedCroppedAreaPixels?: any, passedRotation?: any) => Promise<void>;
	downloadSaveLoading: boolean;
	showObjectsFollowUpTooltip: boolean;
	draftCropName: string;
	draftCroppedArea: any;
	expandBackgroundTabSelected: boolean;
	lockMasks: boolean;
	cropOnlyImageUrl: any;
};

interface StyleProps {
	fullscreen?: boolean;
	imageRef: RefObject<HTMLImageElement>;
	objectListHover: boolean;
	isUserHasDownloadPsdCredits: boolean;
	brushConfig: any;
	isIframe: boolean;
}

function ToolsImage({
	objectsTooltipPosition,
	draftZoom,
	setDraftZoom,
	setActiveCropSizeBtn,
	activeCropSizeBtn,
	getProcessingOperation,
	textOnImage,
	disableDownloadButton,
	imageUrl: passedImageUrl,
	imageVH,
	videoUrl,
	loading,
	minZoom,
	maxZoom,
	cropImageFunction,
	proccessing,
	originalImageDimensions: passedOriginalImageDimensions,
	onUndo,
	onChangeClickedIncreaseResolutionButton,
	clickedIncreaseResButton,
	pointOfInterests,
	setCropName,
	imageRef,
	increaseResolution,
	selectedPoint,
	onObjectHover,
	clearObjectHover,
	objectOnHover,
	setSelectedPoint,
	generatingImageMetric,
	fullscreen,
	onFullscreenChange,
	hasPointOfInterest,
	drawerDirection,
	setImageScore,
	setPointOfInterests,
	resetPointsOfInterests,
	layersURL,
	openCreateVideoPopup,
	setOpenCreateVideoPopup,
	originalImage,
	originalImageWithWatermark,
	isCompareMode,
	isImageMode,
	fetchCameraVideo,
	cameraMotionVideo,
	cameraVideoStatus,
	isCameraMovementTabSelected,
	isImageLoading,
	setIsImageLoading,
	doDownloadVideo,
	isTextEditorTabSelected,
	isLogoTabSelected,
	isObjectsTabSelected,
	isObjectsAutomaticTabSelected,
	isObjectsManualBrushTabSelected,
	brushConfig,
	objectsTabCanvasRef,
	ChangeImageOnErrorOnLoad,
	croppedArea,
	CropTabselected,
	cropZoom,
	setCropZoom,
	cropName,
	setCroppedAreaPixels,
	croppedAreaPixels,
	setCroppedAreaPercentage,
	croppedAreaPercentage,
	rotation,
	setRotation,
	hideImageUrlLoader,
	isDownloadPsdLoading,
	onRedo,
	canUndo,
	canRedo,
	canCompare,
	setIsCompareMode,
	onResetActions,
	draftCroppedAreaPercentage,
	draftCroppedAreaPixels,
	draftRotation,
	setDraftCroppedAreaPercentage,
	setDraftCroppedAreaPixels,
	setDraftRotation,
	previewImage,
	setPreviewImage,
	setCropImageUrl,
	cropImageUrl,
	croppedImage,
	setCroppedImage,
	zoomPersantage,
	setZoomPersantage,
	cropHeight,
	setCropHeight,
	cropWidth,
	setCropWidth,
	naturalSize,
	setNaturalSize,
	isUserHasIncreaseResolutionCredits,
	setIsUserHasIncreaseResolutionCredits,
	setShowIncreaseResolutionPopUp,
	showIncreaseResolutionPopUp,
	imageOnLoadError,
	setImageOnLoadError,
	downloadSaveLoading,
	setDownloadSaveLoading,
	draftCropName,
	isUncropApplied,
	draftCroppedArea,
	createCroppedImage,
	expandBackgroundTabSelected,
	showObjectsFollowUpTooltip,
	lockMasks,
	cropOnlyImageUrl,
}: ToolsImageProps) {
	const context = useContext(Context);
	const history = useHistory();
	const [isUserHasDownloadPsdCredits, setIsUserHasDownloadPsdCredits] = useState(false);
	const [campaignLoading, setCampaignLoading] = useState(false);
	const zoomSliderStepSize = 0.2;
	const [isPsdLoading, setIsPsdLoading] = useState(isDownloadPsdLoading);
	const [selectedMenuItem, setSelectedMenuItem] = useState(0);
	const [originalImageDimensions, setOriginalImageDimensions] =
		useState<{
			width: number;
			height: number;
		}>(passedOriginalImageDimensions);
	const { isIframe } = IsIframe();

	const classes = useStyles({
		fullscreen: fullscreen,
		imageRef: imageRef,
		objectListHover: !!objectOnHover,
		isUserHasDownloadPsdCredits: isUserHasDownloadPsdCredits,
		brushConfig: brushConfig,
		isIframe: isIframe(),
	});

	function getProgressBarTime() {
		if (isImageLoading) {
			return 0;
		}
		if (isPsdLoading) {
			return 30;
		}
		switch (getProcessingOperation()) {
			case "replaceBg":
				return 10;
			case "presentersStyle":
				return 10;
			case "removeObj":
				return 4;
			case "increaseRes":
				return 30;
			default:
				return undefined;
		}
	}

	const useErrorPopupVar: any = useErrorPopup();
	const { isGuestUser } = IsGuestUser();
	const [imageUrl, setImageUrl] = useState<string>();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [mouseOnImage, setMouseOnImage] = useState(false);
	const [displayedImageHeight, setDisplayedImageHeight] = useState<number>();
	const [displayedImageWidth, setDisplayedImageWidth] = useState<number>();
	const [expandTabDisplayedWidth, setExpandTabDisplayedWidth] = useState<number>();
	const [expandTabDisplayedHeight, setExpandTabDisplayedHeight] = useState<number>();

	const { t } = useTranslation();

	const { user, iframeConfig, systemView } = context;
	const [points, setPoints] = useState<any[]>([]);
	const [stage, setCurrentStage] = useState<AnalysisStage>(AnalysisStage.NONE);
	const windowWidth = useWindowSize().width;

	const imageWrapperRef = useRef<HTMLDivElement>();
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const theme = useTheme();
	const [cropImageRef, setCropImageRef] = useState<RefObject<HTMLImageElement>>();

	useEffect(() => {
		const fetchUserHasIncreaseResolutionCredits = async () => {
			return true;
			// return await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.INCREASE_RESOLUTION,
			// 	isIframe(),
			// 	context,
			// 	false
			// );
		};
		if (isGuestUser()) {
			setIsUserHasIncreaseResolutionCredits(false);
		} else {
			fetchUserHasIncreaseResolutionCredits().then((hasIncreaseResolutionCredits: boolean) => {
				setIsUserHasIncreaseResolutionCredits(hasIncreaseResolutionCredits ?? false);
			});
		}

		const fetchUserHasDownloadPsdCredits = async () => {
			return true;
			// return await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.DOWNLOAD_PSD,
			// 	isIframe(),
			// 	context,
			// 	false
			// );
		};

		if (isGuestUser()) {
			setIsUserHasDownloadPsdCredits(false);
		} else {
			fetchUserHasDownloadPsdCredits().then((hasDownloadPsdCredits: boolean) => {
				setIsUserHasDownloadPsdCredits(hasDownloadPsdCredits ?? false);
			});
		}
	}, [context.user]);

	useEffect(() => {
		if (passedOriginalImageDimensions) {
			setOriginalImageDimensions(passedOriginalImageDimensions);
		}
	}, [passedOriginalImageDimensions, passedOriginalImageDimensions.width, passedOriginalImageDimensions.height]);
	useEffect(() => {
		if (croppedImage ?? imageUrl) {
			getImageMeta(croppedImage ?? imageUrl).then((img: any) => {
				const imageHeight = img.height ?? 0;
				const imageWidth = img.width ?? 0;
				const containerHeight = imageRef?.current?.height ?? 0;
				const containerWidth = imageRef?.current?.width ?? 0;
				setDisplayedImageHeight(imageHeight);
				setDisplayedImageWidth(imageWidth);
				setExpandTabDisplayedHeight(imageHeight);
				setExpandTabDisplayedWidth(imageWidth);
				if (imageWidth <= containerWidth && imageHeight <= containerHeight) setZoomPersantage(100);
				else {
					setZoomPersantage(
						Math.trunc(Math.min(containerHeight / imageHeight, containerWidth / imageWidth) * 100)
					);
				}
			});
		}
	}, [
		imageUrl,
		croppedImage,
		imageRef.current?.height,
		imageRef.current?.width,
		imageRef.current?.naturalHeight,
		imageRef.current?.naturalWidth,
	]);
	useEffect(() => {
		if (croppedArea && expandBackgroundTabSelected && imageUrl) {
			getImageMeta(imageUrl).then((img: any) => {
				const imageHeight = croppedArea.height ?? 0;
				const imageWidth = croppedArea.width ?? 0;
				const containerHeight = img.height ?? 0;
				const containerWidth = img.width ?? 0;
				setExpandTabDisplayedHeight(imageHeight);
				setExpandTabDisplayedWidth(imageWidth);
				if (imageWidth <= containerWidth && imageHeight <= containerHeight) setZoomPersantage(100);
				else {
					setZoomPersantage(
						Math.trunc(Math.min(containerHeight / imageHeight, containerWidth / imageWidth) * 100)
					);
				}
			});
		}
	}, [croppedArea]);

	// useEffect(() => {
	// 	//reset all values
	// 	setCropIimageValues();
	// }, [imageUrl]);

	// function setCropIimageValues() {
	// if (imageUrl) {
	// 	console.log("imageUrl", imageUrl);
	// 	getMeta(imageUrl ? imageUrl : "", (err, img: any) => {
	// 		console.log(err);
	// 		cropImageFunction(img.naturalWidth, img.naturalHeight, false);
	// 	});
	// }
	// }

	useEffect(() => {
		if (passedImageUrl && passedImageUrl.length > 0) {
			!hideImageUrlLoader && setIsImageLoading(true);
			setImageUrl(passedImageUrl);
			if (!croppedImage || isUncropApplied) setCropImageUrl(passedImageUrl);

			// const imageAbortController = new AbortController();
			// pullUntilFileIsAvailableFromBackend(
			// 	passedImageUrl,
			// 	"main-img",
			// 	imageAbortController,
			// 	15,
			// 	1
			// )
			// 	.then(() => {
			// 		setImageUrl(passedImageUrl);
			// 		if (!croppedImage) setCropImageUrl(passedImageUrl);
			// 		setImgaeErrorOnload(false);
			// 	})
			// 	.catch((e) => {
			// 		imageAbortContr oller?.abort();
			// 		setImgaeErrorOnload(true);
			// 	});
			// return () => {
			// 	imageAbortController?.abort();
			// };
		}
	}, [passedImageUrl]);

	useEffect(() => {
		let stage1: NodeJS.Timeout, stage2: NodeJS.Timeout;
		if (proccessing) {
			setCurrentStage(AnalysisStage.A);
			stage1 = setTimeout(() => {
				setCurrentStage(AnalysisStage.B);
				stage2 = setTimeout(() => {
					setCurrentStage(AnalysisStage.C);
				}, 2500);
			}, 2500);
		}
		return () => {
			clearTimeout(stage1);
			clearTimeout(stage2);
		};
	}, [proccessing]);

	useOutsideClick(imageRef, () => {
		fullscreen && onFullscreenChange(false);
	});

	useOutsideClick(videoRef, () => {
		fullscreen && onFullscreenChange(false);
	});

	useEventListener({
		type: "keydown",
		listener: (event: any) => {
			if (event.key === "Escape" && fullscreen) {
				onFullscreenChange(false);
			}
		},
	});

	{
	}
	const onImageErrorFun = () => {
		ChangeImageOnErrorOnLoad();
		setImageOnLoadError(true);
	};

	useEffect(() => {
		const element = document.getElementById(objectOnHover.substring(objectOnHover.length - 7));
		if (element && mouseOnImage) element.scrollIntoView({ behavior: "smooth" });
	}, [objectOnHover]);

	useEffect(() => {
		setCroppedImage(null);
	}, [activeCropSizeBtn]);
	const handleDownloadButton = async (isPsd: boolean) => {
		if (isPsd) {
			if (imageUrl) {
				try {
					await doDownloadPSD();
				} catch (err) {
					console.log(err);
					useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
					setIsImageLoading && setIsImageLoading(false);
				}
			}
		} else {
			if (!isGuestUser()) {
				if (imageUrl) {
					try {
						await doDownloadImage();
					} catch (err) {
						console.log(err);
						useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
						setIsImageLoading && setIsImageLoading(false);
					}
				}
			} else {
				context.setAuthDialogSource("Download image");
				context.setAuthDialogState(true);
			}
		}
	};

	const sendCampaignOpenPostMessage = (vhash: string, data: any = {}) => {
		sendPostMessage(IframePostMessageTypes.CampaignOpen, data, vhash, context?.iframeConfig);
	};

	const handleUseInCampaign = async (e: any) => {
		if (imageUrl) {
			try {
				setCampaignLoading(true);
				const fileData = await fetch(imageUrl);
				const fileBlob = await fileData.blob();
				const file = new File([fileBlob], `${uuid4()}.png`, {
					type: "image/png",
				});
				const resp = await uploadImageViaApi(file, MODIFIED_IMAGES_ORG_ID, false);
				const res = resp.data as any;
				if (res && res.visualId && res.status) {
					sendCampaignOpenPostMessage(res.visualId, { imageUrl: imageUrl });
					if (!context.iframeConfig?.disableNav) {
						history.push(RouterConstants.campaignGeneration.path, {
							vhash: res.visualId,
							imageUrl: imageUrl,
						});
					}
				}
			} catch (err) {
				console.log(err);
				useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
				setIsImageLoading && setIsImageLoading(false);
			} finally {
				setCampaignLoading(false);
			}
		}
	};
	const doDownloadPSD = async () => {
		try {
			// if (isGuestUser()) {
			// 	context.setDisplayUpgradeWebSubscriptionPopup(true);
			// 	return;
			// }
			setIsPsdLoading(true);
			setIsImageLoading && setIsImageLoading(true);
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.DOWNLOAD_PSD,
			// 	isIframe(),
			// 	context,
			// 	false
			// );
			// if (!hasCredits) {
			// 	setIsImageLoading && setIsImageLoading(false);
			// 	setIsPsdLoading(false);
			// 	context.setDisplayUpgradeWebSubscriptionPopup(true);
			// 	return;
			// }
			Analytics.logEvent(ANALYTICS_EVENTS.DOWNLOAD_PSD, {
				image_vh: imageVH,
			});
			await Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_DOWNLOAD);
			toast.info(<Typography>Preparing PSD for download</Typography>, {
				toastId: "psd_id",
				autoClose: false,
			});

			const client = BriaAPI.getInstance(imageVH);
			const res = await client.callNonSidCalls(
				client.getLastApiCallSid(),
				organizationUid,
				true,
				undefined,
				true
			);

			const response = await client.imageToPSD(
				res?.data?.sid ?? client.getLastApiCallSid(),
				clickedIncreaseResButton
			);

			await consumeUserCredits(SubscriptionCreditsTypes.DOWNLOAD_PSD);
			await startDownload(response.data.res_path, "download-psd", imageVH ? imageVH : "", undefined, true);
			toast.update("psd_id", {
				render: (
					<Typography>
						PSD file is
						<strong> downloaded</strong>
					</Typography>
				),
				type: toast.TYPE.SUCCESS,
				autoClose: 3000,
			});
			const modified: boolean = client.getApiCallsMap().size > 0;
			await addRecordToDownloads(imageVH ? imageVH : "", modified, "download", "psd");
		} catch (err) {
			captureException(err);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		} finally {
			setIsPsdLoading(false);
			setIsImageLoading && setIsImageLoading(false);
		}
	};
	const doDownloadImage = async () => {
		try {
			setDownloadSaveLoading && setDownloadSaveLoading(true);
			setIsImageLoading && setIsImageLoading(true);
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.DOWNLOAD,
			// 	isIframe(),
			// 	context,
			// 	false
			// );
			// if (!hasCredits) {
			// 	setDownloadSaveLoading && setDownloadSaveLoading(false);
			// 	setIsImageLoading && setIsImageLoading(false);
			// 	window.open(RouterConstants.WEB_PLANS.path, "_blank");
			// 	return;
			// }

			toast.info(<Typography>{t("preparingImageForDownload")}</Typography>, {
				toastId: "my_id",
				autoClose: false,
			});

			Analytics.logEvent(ANALYTICS_EVENTS.DOWNLOAD_IMAGE, {
				image_vh: imageVH,
			});
			await Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_DOWNLOAD);

			const instance = BriaAPI.getInstance(imageVH);
			let imgSrc = imageUrl ?? "";
			await consumeUserCredits(SubscriptionCreditsTypes.DOWNLOAD);
			await startDownload(
				imgSrc,
				"download-img",
				imageVH ? imageVH : "",
				isUncropApplied ? undefined : croppedImage,
				undefined,
				!isUncropApplied && croppedImage ? "png" : undefined
			);
			toast.update("my_id", {
				render: (
					<Typography>
						{t("imageWas")}
						<strong>{t("downloaded")}</strong>
					</Typography>
				),
				type: toast.TYPE.SUCCESS,
				autoClose: 3000,
			});
			const modified: boolean = instance.getApiCallsMap().size > 0;
			// ((imageRef && imageRef?.current?.naturalWidth) ?? 0) <= 512 &&
			// 	!isUserHasIncreaseResolutionCredits &&
			// 	!context.user?.settings?.dontShowIncreaseResPopUp &&
			// 	setShowIncreaseResolutionPopUp &&
			// 	setShowIncreaseResolutionPopUp(true);
			await addRecordToDownloads(imageVH ? imageVH : "", modified);
			setDownloadSaveLoading && setDownloadSaveLoading(false);
			setIsImageLoading && setIsImageLoading(false);
		} catch (e) {
			setDownloadSaveLoading && setDownloadSaveLoading(false);
			setIsImageLoading && setIsImageLoading(false);
			captureException(e);
		}
	};
	const OnImageIcons = () => {
		return (
			<Box className={classes.onImageIconsContainer}>
				<Box className={classes.fullScreenIcon} display="flex" flexDirection="column">
					{fullscreen ? (
						<IconButton
							onClick={() => {
								onFullscreenChange(false);
							}}
							className={classes.whiteIconButton}
						>
							<MinWhiteIcon className={classes.whiteIcon} width="28" height="28" />
						</IconButton>
					) : (
						<>
							<IconButton
								onClick={() => {
									onFullscreenChange(true);
								}}
								className={classes.whiteIconButton}
							>
								<MaxWhiteIcon className={classes.whiteIcon} width="31" height="31" />
							</IconButton>
						</>
					)}
				</Box>
			</Box>
		);
	};

	const { width: imageWidth, height: imageHeight } = useResizeObserver<HTMLImageElement>({ ref: imageRef });

	useEffect(() => {
		if (!isUncropApplied && !CropTabselected && !expandBackgroundTabSelected && croppedImage) {
			createCroppedImage(false, croppedAreaPixels, rotation);
		}

		if (imageRef.current || (cropImageRef?.current && croppedImage)) {
			const imageRefData = {
				top: cropImageRef?.current?.offsetTop ?? imageRef.current!.offsetTop,
				left: cropImageRef?.current?.offsetLeft ?? imageRef.current!.offsetLeft,
				width: cropImageRef?.current?.clientWidth ?? imageRef.current!.clientWidth,
				height: cropImageRef?.current?.clientHeight ?? imageRef.current!.clientHeight,
			};

			const arr: any[] = [];
			pointOfInterests.forEach((el) => {
				const croppedArea = {
					x: (croppedAreaPercentage.x / 100) * el.originalImageWidth,
					y: (croppedAreaPercentage.y / 100) * el.originalImageHeight,
					w: (croppedAreaPercentage.width / 100) * el.originalImageWidth,
					h: (croppedAreaPercentage.height / 100) * el.originalImageHeight,
				};

				//filter boxes outside the cropping area
				if (
					croppedArea.w > 0 &&
					croppedArea.h > 0 &&
					(el.rect?.x > croppedArea.x + croppedArea.w ||
						el.rect?.y > croppedArea.y + croppedArea.h ||
						el.rect?.x + el.rect?.w < croppedArea.x ||
						el.rect?.y + el.rect?.h < croppedArea.y)
				) {
					return;
				}

				// deduce croppedArea location to get the new object box location relative to the original image
				const transformedRect = {
					x: el.rect?.x ? el.rect?.x - croppedArea.x : 0,
					y: el.rect?.y ? el.rect?.y - croppedArea.y : 0,
					w: el.rect?.w ?? 0,
					h: el.rect?.h ?? 0,
				};

				// we have originalImageWidth (from vdrObject.orgWidth: this is the original image width)
				// and resizedImageWidth (from vdrObject.width: this is the resized image width)
				// rect coords are relative to the original image dimensions
				// so we need first to find the ratio (resizedImageWidthRatio) between the original image and the resized image, to convert the rect coords to be relative to the resized image dimensions
				// then we need find the ratio (widthRatio) between the resized image and the browser displayed image to find the final rect coords
				// special case when we have crop, we need to find the ratio based on the cropped image size, not the original image size
				const widthRatio = imageRefData.width / (croppedArea.w > 0 ? croppedArea.w : el.originalImageWidth);
				const heightRatio = imageRefData.height / (croppedArea.h > 0 ? croppedArea.h : el.originalImageHeight);

				// the new rect converted to be relative to the rendered image
				const position = {
					height: heightRatio * transformedRect.h,
					width: widthRatio * transformedRect.w,
					top: imageRefData.top + heightRatio * transformedRect.y,
					left: imageRefData.left + widthRatio * transformedRect.x,
					lable: el.label,
				};

				arr.push({
					id: el.id,
					objectType: el.objectType,
					position: position,
					rect: position,
					oracle: el.oracle,
				});
			});
			setPoints(arr);
			if (JSON.stringify(arr.map((x) => x.id)) !== JSON.stringify(pointOfInterests.map((x) => x.id))) {
				setPointOfInterests(pointOfInterests.filter((pt) => arr.some((a) => a.id === pt.id)));
			}
		}
		//rest the value of the zoom when crop tab is not active, to solve the library bug
		if (!CropTabselected && !expandBackgroundTabSelected && croppedImage && !isUncropApplied) {
			setCropZoom(1);
		}
	}, [
		imageRef,
		fullscreen,
		loading,
		isImageLoading,
		pointOfInterests,
		imageWidth,
		imageHeight,
		isTextEditorTabSelected,
		imageRef.current?.offsetLeft,
		imageRef.current?.offsetTop,
		imageUrl,
		cropImageUrl,
		croppedImage,
		CropTabselected,
		isUncropApplied,
	]);

	const [disableFaceTooltip, setDisableFaceTooltip] = useState(true);

	if (!isImageMode && videoUrl) {
		return (
			<>
				<Box className={clsx(classes.imgContainer, fullscreen && classes.imgContainerFullscreen)}>
					<Box
						position="relative"
						className={clsx(
							classes.imageWrappper,
							{
								[classes.maxDimentionsBottom]: drawerDirection === "bottom",
							},
							{
								[classes.maxDimentionsSide]: drawerDirection !== "bottom",
							}
						)}
					>
						{(proccessing || cameraVideoStatus === "Progress") && (
							<Box position="absolute" width="100%" zIndex={1}>
								<LinearProgress />
							</Box>
						)}
						<video
							ref={(el) => (videoRef.current = el)}
							key={videoUrl}
							muted
							loop
							autoPlay
							controls
							plays-inline
							disablePictureInPicture
							controlsList="nodownload"
							className={clsx(classes.videoContainer)}
						>
							<source src={videoUrl} type="video/mp4" />
							<p>
								Your browser doesn't support HTML5 video. Here is a
								<a href={videoUrl}>link to the video</a> instead.
							</p>
						</video>
					</Box>
					{/* {!fullscreen && (
						<Box
							className={classes.imageShadow}
							style={{
								width: `${
									imageRef.current?.clientWidth ?? 0
								}px`,
							}}
						/>
					)} */}
					{user?.isAdmin() && (
						<Box display="flex" justifyContent="center" alignItems="center">
							<BriaButton
								buttonType={BUTTONTYPES.PRIMARY}
								disabled={disableDownloadButton}
								onClick={async () => {
									doDownloadVideo();
									Analytics.logEvent(ANALYTICS_EVENTS.DOWNLOAD_VIDEO, { image_vh: imageVH });
									await Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_DOWNLOAD);
									toast.success(
										<Typography>
											Video was <strong>downloaded</strong>
										</Typography>
									);
								}}
							>
								{t("download")}
							</BriaButton>
						</Box>
					)}
				</Box>
			</>
		);
	}

	const handleIncreaseResButtonClick = async (clickedButton: number | null) => {
		const userHasCredits = true;
		// const userHasCredits = await checkIfUserHasCredits(
		// 	history,
		// 	SubscriptionCreditsTypes.INCREASE_RESOLUTION,
		// 	isIframe(),
		// 	context,
		// 	false
		// );
		// if (!userHasCredits || isGuestUser()) {
		// 	context.setDisplayUpgradeWebSubscriptionPopup(true);
		// 	return;
		// }
		userHasCredits && (await onChangeClickedIncreaseResolutionButton(clickedButton));
		// if (imageUrl) {
		// 	getMeta(passedImageUrl, (err, img: any) => {
		// 		console.log(img);
		// 		cropImageFunction(img.naturalWidth, img.naturalHeight, false);
		// 	});
		// }
	};
	if (isCameraMovementTabSelected && cameraMotionVideo) {
		return (
			<>
				<Box className={clsx(classes.imgContainer, fullscreen && classes.imgContainerFullscreen)}>
					<Box
						position="relative"
						className={clsx(
							classes.imageWrappper,
							{
								[classes.maxDimentionsBottom]: drawerDirection === "bottom",
							},
							{
								[classes.maxDimentionsSide]: drawerDirection !== "bottom",
							}
						)}
					>
						{(isImageLoading || proccessing || cameraVideoStatus === "Progress") && (
							<Box position="absolute" width="100%" zIndex={1}>
								<LinearProgress />
							</Box>
						)}
						<OnImageIcons />
						<video
							ref={(el) => (videoRef.current = el)}
							key={cameraMotionVideo}
							muted
							loop
							autoPlay
							controls
							disablePictureInPicture
							width={imageRef.current?.clientWidth ?? 0}
							height={imageRef.current?.clientHeight ?? 0}
						>
							<source src={cameraMotionVideo} type="video/mp4" />
							<p>
								Your browser doesn't support HTML5 video. Here is a
								<a href={cameraMotionVideo}>link to the video</a> instead.
							</p>
						</video>
					</Box>
					{!fullscreen && (
						<Box
							className={classes.imageShadow}
							style={{
								width: `${imageRef.current?.clientWidth ?? 0}px`,
							}}
						/>
					)}
					{!fullscreen && (
						<Box display="flex" justifyContent="center" alignItems="center">
							<BriaButton
								buttonType={BUTTONTYPES.PRIMARY}
								disabled={disableDownloadButton}
								onClick={async () => {
									doDownloadVideo(cameraMotionVideo);
									Analytics.logEvent(ANALYTICS_EVENTS.DOWNLOAD_VIDEO, { image_vh: imageVH });
									await Analytics.pushHubspotEvent(context, HUBSPOT_EVENTS.ASSET_DOWNLOAD);
									toast.success(
										<Typography>
											Video was <strong>downloaded</strong>
										</Typography>
									);
								}}
							>
								{t("download")}
							</BriaButton>
						</Box>
					)}
				</Box>
			</>
		);
	}

	const briaApiClient = BriaAPI.getInstance(imageVH);
	const imageDimsForIncreaseRes = {
		width: croppedImage ? croppedArea.width : originalImageDimensions.width,
		height: croppedImage ? croppedArea.height : originalImageDimensions.height,
	};
	return (
		<>
			{/*<IncreaseResolutionPopUp*/}
			{/*	onClose={() => setShowIncreaseResolutionPopUp(false)}*/}
			{/*	open={showIncreaseResolutionPopUp}*/}
			{/*/>*/}
			<Box className={clsx(classes.imgContainer, fullscreen && classes.imgContainerFullscreen)}>
				{/* <BugReportForm feedbackSource={"react-web"} /> */}
				{!fullscreen && (
					<Box
						className={clsx(classes?.imgHeader)}
						style={{
							display: !isImageLoading || !proccessing || !loading ? "flex" : "none",
						}}
					>
						<Grid style={{ display: "flex", alignItems: "center" }}>
							<Grid
								item
								className={clsx(
									classes.grid,
									selectedPoint?.objectType === BriaObjectType.camera && classes.disabledIconItem
								)}
							>
								<Tooltip
									title={t("compare")}
									arrow
									placement="top"
									classes={{
										tooltip: classes.tooltipStyle,
										arrow: classes.tooltipArrowStyle,
									}}
								>
									<IconButton
										disableRipple
										disabled={!canCompare}
										className={clsx(classes.padding, {
											[classes.selectedStyle]: canCompare && isCompareMode,
										})}
										onClick={() => {
											if (selectedPoint?.objectType !== BriaObjectType.camera) {
												const selectedValue = isCompareMode ? 1 : 2;
												const value = selectedValue === 1 ? 2 : 1;
												setIsCompareMode(value === 1);
											}
										}}
									>
										<CompareButton
											className={clsx({
												[classes.iconItem]: true,
												[classes.disabledIconItem]: !canCompare,
											})}
										/>
									</IconButton>
								</Tooltip>
							</Grid>

							<Grid item className={classes.grid}>
								<Tooltip
									classes={{
										tooltip: classes.tooltipStyle,
										arrow: classes.tooltipArrowStyle,
									}}
									title={t("undo")}
									arrow
									placement="top"
								>
									<IconButton
										disableRipple
										disabled={!canUndo}
										onClick={() => {
											onUndo();
										}}
										className={clsx(classes.padding, classes.focusedIconButton)}
									>
										<UndoIcon
											className={clsx({
												[classes.iconItem]: true,
												[classes.disabledIconItem]: !canUndo,
											})}
										/>
									</IconButton>
								</Tooltip>
							</Grid>
							<Grid item className={classes.grid}>
								<Tooltip
									classes={{
										tooltip: classes.tooltipStyle,
										arrow: classes.tooltipArrowStyle,
									}}
									title={t("redo")}
									arrow
									placement="top"
								>
									<IconButton
										disableRipple
										disabled={!canRedo}
										onClick={() => {
											onRedo();
										}}
										className={clsx(classes.padding, classes.focusedIconButton)}
									>
										<RedoIcon
											className={clsx({
												[classes.iconItem]: true,
												[classes.disabledIconItem]: !canRedo,
											})}
										/>
									</IconButton>
								</Tooltip>
							</Grid>

							<Grid item className={classes.grid}>
								<Tooltip
									classes={{
										tooltip: classes.tooltipStyle,
										arrow: classes.tooltipArrowStyle,
									}}
									title={t("reset")}
									arrow
									placement="top"
								>
									<IconButton
										disableRipple
										onClick={onResetActions}
										className={clsx(classes.padding, classes.focusedIconButton)}
									>
										<ResetIcon className={classes.iconItem} />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
						{windowWidth > 1630 && (
							<>
								<BriaInfoBox
									condition={
										!fullscreen &&
										(!context.iframeConfig ||
											(!!context.iframeConfig && !context.iframeConfig.whiteLabel)) &&
										!context.user?.getHidePlaygroundInfoBoxSettings(isIframe()) &&
										briaApiClient
											.getApiCallsMap()
											.has(
												ApiActions.FACE_MANIPULATIONS ||
													briaApiClient.getApiCallsMap().has(ApiActions.RECAST_MODEL)
											) &&
										!briaApiClient.getApiCallsMap().has(ApiActions.REMOVE_OBJECT)
									}
									iconColor={"#FFC107"}
									position={"relative"}
									text={
										"Bria slightly modifies presenter’s identity to comply with our ethics guidelines."
									}
									onButtonClick={() => {
										context.user?.updateHidePlaygroundInfoBoxSettings(true, isIframe());
									}}
								/>
								<BriaInfoBox
									condition={
										!fullscreen &&
										(!context.iframeConfig ||
											(!!context.iframeConfig && !context.iframeConfig.whiteLabel)) &&
										!context.user?.getHidePlaygroundObjectsInfoBoxSettings(isIframe()) &&
										briaApiClient.getApiCallsMap().has(ApiActions.REMOVE_OBJECT)
									}
									iconColor={"#FFC107"}
									position={"relative"}
									text={"Tip: You can fine-tune the removal of objects using the Manual Brush tool."}
									onButtonClick={() => {
										context.user?.updateHidePlaygroundObjectsInfoBoxSettings(true, isIframe());
									}}
								/>
							</>
						)}
						<ShowAt
							showAlways
							condition={
								!isIframe() ||
								(isIframe() &&
									context.iframeConfig?.enabledFeatures.some(
										(feature: string) => ToolConfigTabsTypes.SuperResolution.toString() === feature
									))
							}
						>
							<Box
								display="flex"
								position="relative"
								justifyContent="flex-end"
								alignItems="center"
								gridGap="8px"
								// width={230}
							>
								{((context?.user?.isAdmin() && context.systemView === SystemViewTypes.Admin) ||
									(isIframe() && iframeConfig?.enabledPages.campaign === true)) && (
									<BriaButton
										className={classes.useInCampaignButton}
										buttonType={BUTTONTYPES.TEXTBUTTON}
										onClick={(e) => handleUseInCampaign(e)}
									>
										<span
											className={classes.dropDownText}
											style={{ marginRight: 0, display: "flex", alignItems: "center" }}
										>
											{t("useInCampaign")}{" "}
											{campaignLoading && <CircularProgress className={classes.buttonLoader} />}
										</span>
									</BriaButton>
								)}
								<FormControl>
									<Button
										className={clsx({
											[classes.increaseResSelect]: true,

											[classes.selectedDropDown]: open,
										})}
										disabled={
											loading || isImageLoading || proccessing || cameraVideoStatus === "Progress"
										}
										// fullWidth={true}
										// labelId="increase-resolution-label"
										// id="increase-resolution"
										value={clickedIncreaseResButton == null ? 0 : clickedIncreaseResButton}
										// label="Increase Resolution"
										aria-controls={open ? "basic-menu" : undefined}
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										onClick={async (event) => {
											setAnchorEl(event.currentTarget);
										}}
									>
										<>
											<span className={classes.dropDownText}>{t("increaseResolution")}</span>
											<ArrowDown />
										</>
									</Button>
									<Menu
										PopoverClasses={{
											paper: classes.menuStyle,
										}}
										id="demo-simple-select"
										onClose={() => setAnchorEl(null)}
										open={open}
										anchorEl={anchorEl}
										keepMounted
										elevation={0}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										getContentAnchorEl={null}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}
										PaperProps={{
											style: {
												borderRadius: 8,
												width: 228,
												boxShadow: " 0px 2px 16px rgba(36, 0, 88, 0.08);",
											},
										}}
									>
										<MenuItem
											selected={selectedMenuItem === 0}
											value={0}
											classes={{
												root: classes.menuItemRoot,
												selected: classes.selectedMenuItem,
											}}
											className={classes.increaseResItem}
											onClick={async (event) => {
												setAnchorEl(null);
												setSelectedMenuItem(0);
												try {
													await handleIncreaseResButtonClick(
														Number(event.currentTarget.value)
													);
												} catch (err) {
													useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
													setIsImageLoading(false);
												}
											}}
										>
											{imageDimsForIncreaseRes.width}
											{" x "}
											{imageDimsForIncreaseRes.height}
											{"px "} ({t("original")})
										</MenuItem>
										<MenuItem
											value={2}
											selected={selectedMenuItem === 2}
											classes={{
												root: classes.menuItemRoot,
												selected: classes.selectedMenuItem,
											}}
											className={classes.increaseResItem}
											onClick={async (event) => {
												setAnchorEl(null);
												setSelectedMenuItem(2);
												try {
													await handleIncreaseResButtonClick(
														Number(event.currentTarget.value)
													);
												} catch (err) {
													useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
													setIsImageLoading(false);
												}
											}}
										>
											{imageDimsForIncreaseRes.width * 2}
											{" x "}
											{imageDimsForIncreaseRes.height * 2}
											{"px "} (x2)
											{!isUserHasIncreaseResolutionCredits && (
												<PremiumIcon
													className={classes.premiumIcon}
													color={theme.palette.primary.dark}
												/>
											)}
										</MenuItem>
										<MenuItem
											value={4}
											selected={selectedMenuItem === 4}
											classes={{
												root: classes.menuItemRoot,
												selected: classes.selectedMenuItem,
											}}
											className={classes.increaseResItem}
											onClick={async (event) => {
												setAnchorEl(null);
												setSelectedMenuItem(4);
												try {
													await handleIncreaseResButtonClick(
														Number(event.currentTarget.value)
													);
												} catch (err) {
													useErrorPopupVar.showErrorPopup(t("someActionsLimited"));
													setIsImageLoading(false);
												}
											}}
										>
											{imageDimsForIncreaseRes.width * 4}
											{" x "}
											{imageDimsForIncreaseRes.height * 4}
											{"px "}
											(x4)
											{!isUserHasIncreaseResolutionCredits && (
												<PremiumIcon
													className={classes.premiumIcon}
													color={theme.palette.primary.dark}
												/>
											)}
										</MenuItem>
									</Menu>
								</FormControl>
								{!isIframe() && (
									<BriaDropdownButton
										btnClass={classes.downloadPSDButton}
										text={t("export")}
										menu={[t("image"), "PSD"]}
										hideArrow={false}
										handleCloseMenu={(e: any) => handleDownloadButton(e === "PSD")}
									/>
								)}
							</Box>
						</ShowAt>
					</Box>
				)}

				<Box
					style={{
						height: "88%",
						width: (CropTabselected || expandBackgroundTabSelected) && !isUncropApplied ? "100%" : "auto",
						// justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						marginTop: 12,
					}}
				>
					<Box
						ref={imageWrapperRef}
						position="relative"
						className={clsx(
							classes.imageWrappper,
							{
								[classes.maxDimentionsBottom]: drawerDirection === "bottom",
							},
							{
								[classes.maxDimentionsSide]: drawerDirection !== "bottom",
							},
							isLogoTabSelected && classes.LogoMaxDimentionsSide
						)}
						style={
							(CropTabselected || expandBackgroundTabSelected) &&
							!isUncropApplied &&
							(!croppedImage || !cropName || fullscreen)
								? {
										width: "100%",
										height: "100%",
										borderRadius: 0,
								  }
								: {}
						}
					>
						<Box className={classes.loadingContainer} position="absolute" width="100%" zIndex={1}>
							<LoadingAnimation
								progressBarTime={getProgressBarTime()}
								loading={(proccessing || cameraVideoStatus === "Progress") && !imageOnLoadError}
							/>
						</Box>

						{!CropTabselected && !expandBackgroundTabSelected && <OnImageIcons />}

						{/*I set the canvas here to capture the ref on the first load and avoid being null*/}
						<div
							style={{
								overflow: "hidden",
								maxWidth: "100%",
								height: "100%",
								textAlign: "center",
								width: "100%",
								display:
									(isObjectsManualBrushTabSelected || isObjectsAutomaticTabSelected) &&
									isObjectsTabSelected
										? isCompareMode
											? "inline-block"
											: "flex"
										: "none",
							}}
						>
							{isObjectsManualBrushTabSelected && (
								<Box className={classes.customCursor} id="customBrushCursor"></Box>
							)}
							<canvas
								onContextMenu={(e) => e.preventDefault()}
								className={clsx({
									[classes.imageLoadingState]: isImageLoading,
								})}
								ref={objectsTabCanvasRef}
								style={{
									borderRadius: 10,
									width: "100%",
									height: "100%",
									border: "1px solid rgb(204, 204, 204)",
									cursor: isObjectsAutomaticTabSelected ? "url(" + MagicWand + "), auto" : "none",
								}}
							/>
							{isObjectsAutomaticTabSelected && (
								<FollowCursorTooltip
									imageRef={objectsTabCanvasRef}
									position={objectsTooltipPosition}
									showObjectsFollowUpTooltip={showObjectsFollowUpTooltip}
									title="Click to remove objects"
								/>
							)}
							{isObjectsAutomaticTabSelected && (
								<Box style={{ pointerEvents: "none" }} className={classes.hoverContianer}>
									{points
										.filter(
											(el) =>
												el.objectType !== BriaObjectType.brand &&
												(selectedPoint?.objectType === BriaObjectType.imageBackground
													? el.objectType !== BriaObjectType.objects &&
													  el.objectType !== BriaObjectType.human
													: el.objectType === selectedPoint?.objectType)
										)
										.map(({ position, rect, id, objectType, oracle }, index) => {
											return (
												<ObjectRect
													key={id}
													objectId={id}
													objectOrder={index}
													onClick={() => {
														setSelectedPoint({
															id: id,
															objectType: objectType,
															index,
															rect,
															oracle,
														});
													}}
													objectOnHover={objectOnHover}
													active={id === selectedPoint?.id}
													width={position.width}
													height={position.height}
													top={position.top}
													left={position.left}
													disableTooltip={disableFaceTooltip}
												/>
											);
										})}
								</Box>
							)}
						</div>
						{/* if error on load  */}
						{!imageOnLoadError ? (
							<>
								{(CropTabselected || expandBackgroundTabSelected) &&
								!isUncropApplied &&
								(!croppedImage || fullscreen) &&
								imageUrl &&
								!isImageLoading &&
								!loading ? (
									<CropImageTool
										minZoom={minZoom}
										maxZoom={maxZoom}
										cropWidth={cropWidth}
										cropHeight={cropHeight}
										naturalSize={naturalSize}
										setCropWidth={setCropWidth}
										setCropHeight={setCropHeight}
										setNaturalSize={setNaturalSize}
										imageWrapperRef={imageWrapperRef}
										previewImage={previewImage}
										croppedArea={croppedArea}
										cropZoom={draftZoom}
										setCropZoom={setDraftZoom}
										activeCropSizeBtn={activeCropSizeBtn}
										cropName={cropName ?? ""}
										imageUrl={imageUrl}
										imageRef={imageRef}
										onCropCompleteCallback={(croppedAreaPixels, croppedAreaPercentage) => {
											setDraftCroppedAreaPixels(croppedAreaPixels);
											setDraftCroppedAreaPercentage(croppedAreaPercentage);
										}}
										rotation={draftRotation}
										croppedAreaPixels={draftCroppedAreaPixels}
										setRotation={setDraftRotation}
										createCroppedImageFunction={createCroppedImage}
										setCropImageRef={setCropImageRef}
										expandBackground={expandBackgroundTabSelected}
									/>
								) : (
									<>
										{isTextEditorTabSelected ? (
											<div
												id="root_image_box"
												className={clsx({
													[classes.imageLoadingState]:
														loading ||
														isImageLoading ||
														proccessing ||
														cameraVideoStatus === "Progress",
												})}
												style={{
													overflow: "hidden",
													maxWidth: "100%",
													height: "100%",
													textAlign: "center",
													width: "100%",
													display: "inline-block",
												}}
											>
												<TextOnImage text={textOnImage} />
												<img
													// crossOrigin="anonymous"
													onContextMenu={(e) => e.preventDefault()}
													id="root_image"
													ref={imageRef}
													key={imageUrl}
													style={{
														...styleFitContainer(),
														width: "auto",
														display: "inline-block",
														borderRadius: 10,
														border: "1px solid #ccc",
													}}
													src={cropImageUrl + "?not-from-cache-please"}
													onLoad={() => {
														setImageOnLoadError(false);
														setIsImageLoading(false);
													}}
													onError={onImageErrorFun}
												/>
											</div>
										) : isLogoTabSelected ? (
											<ShapeEditingImg srcImg={imageUrl} />
										) : (
											cropImageUrl && (
												<ReactCompareSlider
													onContextMenu={(e) => e.preventDefault()}
													style={{
														overflow: "hidden",
														maxWidth: "100%",
														justifyContent: "center",
														height: "100%",
														textAlign: "center",
														width: "100%",
														display: isCompareMode ? "inline-block" : "flex",
														position:
															isObjectsTabSelected &&
															(isObjectsManualBrushTabSelected ||
																isObjectsAutomaticTabSelected)
																? "absolute"
																: "relative",
														left:
															!isCompareMode &&
															isObjectsTabSelected &&
															(isObjectsManualBrushTabSelected ||
																isObjectsAutomaticTabSelected)
																? "-9999px"
																: "0",
													}}
													className={clsx({
														[classes.imageLoadingState]:
															loading ||
															isImageLoading ||
															proccessing ||
															cameraVideoStatus === "Progress",
													})}
													handle={
														<ReactCompareSliderHandle
															buttonStyle={{
																WebkitBackdropFilter: "none",
																backdropFilter: "none",
																border: 0,
																boxShadow: "none",
																display: isCompareMode ? "grid" : "none",
															}}
															linesStyle={{
																opacity: isCompareMode ? 0.5 : 0,
															}}
														/>
													}
													position={isCompareMode ? 50 : 0}
													itemOne={
														<img
															onContextMenu={(e) => e.preventDefault()}
															// crossOrigin="anonymous"
															id="root_image"
															// ref={imageRef}
															style={{
																...styleFitContainer(),
																width: "auto",
																display: "unset",
																borderRadius: 10,
																border: "1px solid #ccc",
																clip: "rect(auto, 0px, auto, auto)",
																backgroundSize: "contain",
																backgroundImage: `url(/transparent.jpg)`,
															}}
															src={
																cropOnlyImageUrl
																	? cropOnlyImageUrl
																	: originalImageWithWatermark !== ""
																	? originalImageWithWatermark
																	: originalImage
															}
															alt="Lets Bria it"
														/>
													}
													itemTwo={
														<>
															<img
																onContextMenu={(e) => e.preventDefault()}
																// crossOrigin="anonymous"
																id="root_image"
																ref={imageRef}
																style={{
																	...styleFitContainer(),
																	width: "auto",
																	display: "unset",
																	backgroundImage: `url(/transparent.jpg)`,
																	backgroundSize: "contain",
																	borderRadius: 10,
																	border: "1px solid #ccc",
																}}
																src={cropImageUrl}
																// key={cropImageUrl} // caused white space after loading the img
																alt="Lets Bria it"
																onLoad={() => {
																	generatingImageMetric();
																	let sleepTimeout = 0;
																	if (
																		selectedPoint?.objectType ==
																		BriaObjectType.brand
																	) {
																		sleepTimeout = 600;
																	}
																	if (
																		!(lockMasks && isObjectsManualBrushTabSelected)
																	) {
																		setTimeout(() => {
																			setIsImageLoading(false);
																		}, sleepTimeout);
																	}
																}}
																onError={onImageErrorFun}
															/>
														</>
													}
												/>
											)
										)}
									</>
								)}
							</>
						) : (
							<ImageError
								imageWidth={"54.1vw"}
								imageHeight={"65.40vh"}
								massageText={"Oops, we couldn't load the image. Please try to reload the page."}
								titleText={"Image unavailable"}
								titleFontSize={"2.85em"}
								largeSvg={true}
								massageTextSize={"1.71em"}
							/>
						)}
						{/* end show error image */}
						{!isTextEditorTabSelected &&
							!isCompareMode &&
							!imageOnLoadError &&
							!isLogoTabSelected &&
							!isObjectsTabSelected &&
							!fullscreen && (
								<Box
									onContextMenu={(e) => e.preventDefault()}
									className={clsx({
										[classes.imageBackground]: !points.some((el) => el.id === selectedPoint?.id),
										[classes.hoverContianer]: !isObjectsAutomaticTabSelected,
									})}
									onMouseEnter={() => {
										setDisableFaceTooltip(false);
									}}
									onMouseLeave={() => {
										setDisableFaceTooltip(true);
									}}
								>
									{selectedPoint && selectedPoint.objectType === BriaObjectType.human && (
										<FeedbackForm setImageScore={setImageScore} />
									)}
									{points
										.filter(
											(el) =>
												el.objectType !== BriaObjectType.brand &&
												(selectedPoint?.objectType === BriaObjectType.imageBackground
													? el.objectType !== BriaObjectType.objects &&
													  el.objectType !== BriaObjectType.human
													: el.objectType === selectedPoint?.objectType)
										)
										.map(({ position, rect, id, objectType, oracle }, index) => {
											if (
												objectType !== BriaObjectType.human &&
												objectType !== BriaObjectType.camera &&
												objectType !== BriaObjectType.objects
											) {
												return (
													<ObjectPoint
														active={id === selectedPoint?.id}
														top={position.top}
														left={position.left}
														onClick={() => {
															setSelectedPoint({
																id: id,
																index,
																objectType: objectType,
																rect,
																oracle,
															});
														}}
													/>
												);
											}
											return (
												<>
													<FaceRect
														key={id}
														objectId={id}
														objectOrder={index}
														objectOnHover={objectOnHover}
														active={id === selectedPoint?.id}
														width={position.width}
														height={position.height}
														top={position.top}
														left={position.left}
														disableTooltip={disableFaceTooltip}
														onClick={() => {
															setSelectedPoint({
																id: id,
																objectType: objectType,
																index,
																rect,
																oracle,
															});
														}}
														onMouseEnter={() => {
															setMouseOnImage(true);
															onObjectHover(id);
														}}
														onMouseLeave={() => {
															setMouseOnImage(false);
															clearObjectHover();
														}}
													/>
												</>
											);
										})}
								</Box>
							)}
					</Box>
					{/* {!fullscreen && (
						<Box
							className={classes.imageShadow}
							style={{
								width: `${
									imageRef.current?.clientWidth ?? 0
								}px`,
							}}
						/>
					)} */}
					<Box
						style={{
							display: expandBackgroundTabSelected ? "flex" : "unset",
							justifyContent: "space-between",
							marginTop: 26,
							alignItems: "center",
						}}
					>
						{!!displayedImageHeight && !!displayedImageWidth && !fullscreen && (
							<Box
								style={{
									display: expandBackgroundTabSelected ? "flex" : "unset",
									width: "50%",
								}}
							>
								{expandBackgroundTabSelected && (
									<Typography
										style={{
											color: "#1A0638",
											fontSize: 14,
											fontWeight: 400,
											display: "flex",
											justifyContent: "center",
											marginRight: 8,
										}}
									>
										{t("resolution")}:{" "}
									</Typography>
								)}
								<Typography
									style={{
										color: "#1A0638",
										fontSize: 14,
										fontWeight: 600,
										display: "flex",
										justifyContent: "center",
									}}
								>
									{cropName && cropName?.length > 1 && (
										<>
											<span>{Parser(draftCropName && draftCropName)} </span>
											<Box
												style={{
													border: "1px solid rgba(91, 91, 91, 0.5)",
													marginInline: "14px",
													height: 20,
												}}
											/>
										</>
									)}
									&#32;&#32;
									{expandBackgroundTabSelected ? expandTabDisplayedWidth : displayedImageWidth}
									&#32;&#32;
									{" x "}
									&#32;&#32;
									{expandBackgroundTabSelected ? expandTabDisplayedHeight : displayedImageHeight}
									{" px "}
									{zoomPersantage !== 100 &&
										zoomPersantage !== 0 &&
										"  (" + zoomPersantage + "%)"}{" "}
								</Typography>
							</Box>
						)}
						{expandBackgroundTabSelected && (
							<Grid item className={classes.controls}>
								<Typography className={classes.zoomText}>{t("zoomImage")}</Typography>
								<>
									<Slider
										disabled={!!croppedImage}
										defaultValue={1}
										value={draftZoom}
										className={classes.sliderStyle}
										onChange={(event: React.ChangeEvent<{}>, value: number) => {
											setDraftZoom(value);
										}}
										//valueLabelDisplay="auto"
										sx={{ width: "200px", ml: "20px" }}
										step={zoomSliderStepSize}
										max={maxZoom}
										min={minZoom}
										align="center"
									/>
								</>
							</Grid>
						)}
					</Box>
				</Box>
				{!fullscreen && !loading && (
					<Box
						display="flex"
						position="relative"
						justifyContent="center"
						alignItems="center"
						className={clsx(classes.downloadButtonContainer)}
					>
						{/* <ShowAt
							showAlways
							condition={
								isIframe() && !iframeConfig?.enableDownload
							}
						>
							<>
								<BriaButton
									buttonType={BUTTONTYPES.PRIMARY}
									disabled={
										downloadSaveLoading ||
										disableDownloadButton
									}
									style={{
										marginTop: 9,
									}}
									onClick={async () => {
										doSaveImage();
									}}
								>
									{t("save")}
								</BriaButton>

								<ShowAt
									showAlways
									condition={iframeConfig?.enabledFeatures.some(
										(feature: string) =>
											ToolConfigTabsTypes.PsdDownload.toString() ===
											feature
									)}
							<ShowAt
							showAlways
							condition={
								isIframe() && !iframeConfig?.enableDownload
							}
						>
							<>
								<BriaButton
									buttonType={BUTTONTYPES.PRIMARY}
									disabled={
										downloadSaveLoading ||
										disableDownloadButton
									}
									style={{
										marginTop: 9,
									}}
									onClick={async () => {
										doSaveImage();
									}}
								>
									{t("save")}
								</BriaButton>

								<ShowAt
									showAlways
									condition={iframeConfig?.enabledFeatures.some(
										(feature: string) =>
											ToolConfigTabsTypes.PsdDownload.toString() ===
											feature
									)}
								>
									<BriaButton
										buttonType={BUTTONTYPES.PRIMARY}
										disabled={
											isPsdLoading ||
											disableDownloadButton
										}
										style={{
											marginTop: 9,
											marginLeft: 16,
										}}
										onClick={async () => {
											doSaveAsPSDImage();
										}}
									>
										{t("saveAsPsd")}
									</BriaButton>
								</ShowAt>
							</>
						</ShowAt>		>
									<BriaButton
										buttonType={BUTTONTYPES.PRIMARY}
										disabled={
											isPsdLoading ||
											disableDownloadButton
										}
										style={{
											marginTop: 9,
											marginLeft: 16,
										}}
										onClick={async () => {
											doSaveAsPSDImage();
										}}
									>
										{t("saveAsPsd")}
									</BriaButton>
								</ShowAt>
							</>
						</ShowAt> */}
						{/* <ShowAt
							showAlways
							condition={
								isIframe() && !!iframeConfig?.enableDownload
							}
						>
							<>
								<DropdownMenu
									mainAction={t("save")}
									subAction={t("download")}
									mainActionLoading={downloadSaveLoading}
									mainActionHandler={async () => {
										doSaveImage();
									}}
									subActionHandler={async () => {
										if (!isGuestUser()) {
											if (imageUrl) {
												try {
													await doDownloadImage();
												} catch (err) {
													useErrorPopupVar.showErrorPopup(
														t("someActionsLimited")
													);
													setIsImageLoading(false);
												}
											}
										} else {
											context.setAuthDialogSource(
												"Download image"
											);
											context.setAuthDialogState(true);
										}
									}}
								></DropdownMenu>

								<ShowAt
									showAlways
									condition={iframeConfig?.enabledFeatures.some(
										(feature: string) =>
											ToolConfigTabsTypes.PsdDownload.toString() ===
											feature
									)}
								>
									<DropdownMenu
										mainAction={t("saveAsPsd")}
										subAction={t("downloadPSD")}
										mainActionLoading={isPsdLoading}
										mainActionHandler={async () => {
											doSaveAsPSDImage();
										}}
										subActionHandler={async () => {
											if (imageUrl) {
												try {
													await doDownloadPSD();
												} catch (err) {
													useErrorPopupVar.showErrorPopup(
														t("someActionsLimited")
													);
													setIsImageLoading(false);
												}
											}
										}}
									></DropdownMenu>
								</ShowAt>
							</>
						</ShowAt> */}
					</Box>
				)}
			</Box>
		</>
	);
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	imgContainer: {
		paddingTop: 20,
		marginRight: "2vw",
		height: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		paddingLeft: 65,
	},
	imgHeader: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		alignItems: "center",
		paddingTop: 10,
		"@media (min-width:1920px)": {
			maxWidth: 1098,
		},
	},
	imageResolutionText: {
		maxWidth: 110,
		fontFamily: "Montserrat",
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "17px",
		color: "#495057",
		margin: "0 12px",
	},
	loadingContainer: {
		position: "absolute",
		display: "flex",
		justifyContent: "center",
		top: "50%",
		transform: "translate(-5%,-50%)",
		zIndex: 100000,
		left: "5%",
	},
	iconItem: {
		fontSize: 30,
	},
	selectedStyle: {
		"&.MuiButtonBase-root": {
			backgroundColor: "rgba(83, 0, 201, 0.05) !important",
			border: " 2px solid rgba(83, 0, 201, 0.05) !important",
			boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02) !important",
		},
	},
	padding: {
		width: 36,
		height: 36,
		fontSize: 16,
		marginRight: 8,
		borderRadius: 8,
		boxSizing: "content-box",
		padding: 0,
		border: "2px solid transparent !important",
		backgroundColor: "transparent !important",
		"&:hover": {
			backgroundColor: "#E7E7E7 !important",
		},
	},
	focusedIconButton: {
		"&.MuiButtonBase-root:focus": {
			backgroundColor: "rgba(83, 0, 201, 0.05) !important",
			border: " 2px solid rgba(83, 0, 201, 0.05) !important",
			boxShadow: "inset 0px 4px 6px rgba(0, 0, 0, 0.02) !important",
		},
	},
	disabledIconItem: {
		opacity: 0.25,
		"& > span": {
			opacity: "0.3 !important",
			pointerEvents: "none",
		},
	},
	downloadAsPsdButton: {
		width: (props) => (props.isUserHasDownloadPsdCredits ? 150 : 170),
		marginTop: 9,
		padding: "8px 8px",
		textTransform: "none",
	},
	downloadButton: {
		width: (props) => (props.isUserHasDownloadPsdCredits ? 150 : 170),
		marginTop: 9,
	},
	imgContainerFullscreen: {
		position: "fixed",
		top: 0,
		left: 0,
		zIndex: 10000,
		width: "100vw",
		height: "100vh",
		background: "rgba(0, 0, 0, 0.68)",
	},
	videoContainer: {
		height: "100%",
		width: "100%",
		objectFit: "contain",
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
	},
	tooltipStyle: {
		backgroundColor: "#FFFFFF",
		boxShadow: "0px 2px 8px rgba(36, 0, 88, 0.08)",
		borderRadius: 8,
		color: "#5B5B5B",
		fontSize: 14,
	},
	tooltipArrowStyle: {
		left: "35% !important",
		"&:before": {
			backgroundColor: "#FFFFFF",
		},
	},
	maxDimentionsBottom: {
		maxWidth: (props) => (props.fullscreen ? "90%" : 1760),
		maxHeight: (props) => (props.fullscreen ? "calc(100vh - 150px)" : "calc(100vh - 370px)"),
	},
	LogoMaxDimentionsSide: {
		width: "100%",
		height: "100%",
	},
	maxDimentionsSide: {
		maxWidth: (props) => (props.fullscreen ? "90%" : 1098),
		// maxHeight: (props) => (props.fullscreen ? "90%" : 700),
		maxHeight: "calc(100% - 49px)",
	},
	videoDimensions: {
		width: 564,
		height: 421,
	},
	grid: { display: "grid", justifyItems: "center" },

	premiumIcon: {
		height: 18,
		marginTop: 4,
		marginLeft: 9,
	},
	toggleItem: {
		cursor: "pointer",
		marginRight: theme.spacing(3.2),
		fontWeight: "bold",
		padding: theme.spacing(2.7, 0),
	},
	img: {
		height: "auto",
		objectFit: "contain",
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		maxHeight: "100%",
		maxWidth: "100%",
	},
	imageMaxDimentionsSide: {
		maxHeight: 940,
		maxWidth: 1009,
	},
	imageLoadingState: {
		filter: "blur(4px)",
	},
	downloadPSDButton: {
		width: 130,
	},
	whiteIcon: {
		fontSize: 16,
		width: 36,
		height: 36,
		"& .MuiSvgIcon-root ": {
			width: 36,
			height: 36,
		},
		//boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
	},
	invalidImage: {
		display: "none",
	},
	imageWrappper: {
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		position: "relative",
		textAlign: "center",
		margin: "0 auto",
	},
	errorText: {
		fontSize: 25,
		paddingTop: 10,
		color: theme.palette.text.primary,
	},
	hoverContianer: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		borderRadius: theme.shape.borderRadius,
		opacity: (props) => (props.objectListHover ? 1 : 0),
		"&:hover": {
			opacity: 1,
		},
	},
	canvasOverlay: {
		width: "100%",
		height: "100%",
		position: "absolute",
		backgroundColor: "#1A06384D",
		zIndex: 0,
	},
	hoverContianerAutomatic: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		borderRadius: theme.shape.borderRadius,
		opacity: (props) => (props.objectListHover ? 1 : 0),
	},
	imageShadow: {
		background:
			"radial-gradient(50% 50% at 50.07% 50%,rgb(162 162 162 / 69%) 0%, rgb(226 220 220 / 21%) 70%, transparent 100%)",
		height: 42,
		margin: "7px auto 0 auto",
	},
	imageBackground: {
		background: "rgba(0,0,0,0.5)",
	},
	zoomText: {
		width: "50%",
		color: "#1A0638",
		fontSize: 14,
		fontWeight: 600,
		minWidth: 140,
	},
	onImageIconsContainer: (props) => ({
		width: "100%",
		position: "absolute",
		background: "transparent",
		zIndex: 999,
	}),
	fullScreenIcon: (props) => ({
		height: 16,
		width: 16,
		position: "absolute",
		right: "20px",
		// left:
		// 	(props.imageRef.current?.offsetLeft ?? 0) +
		// 	(props.imageRef.current?.offsetWidth ?? 0) -
		// 	40,
		top: (props.imageRef.current?.offsetTop ?? 0) + 14,
		background: "transparent",
		zIndex: 999,
	}),
	infoIconWrapper: (props) => ({
		position: "absolute",
		left: (props.imageRef.current?.offsetLeft ?? 0) + 12,
		top: (props.imageRef.current?.offsetTop ?? 0) + 12,
		textAlign: "initial",
	}),
	button: {
		margin: theme.spacing(1),
		padding: "7px 31px",
	},
	whiteIconButton: {
		boxShadow: "none",
		width: 28,
		height: 28,
		color: "transparent",
	},
	iconButton: {
		boxShadow: "none",
		width: 16,
		height: 16,
	},
	icon: {
		fontSize: 16,
	},
	controls: {
		width: "60%",
		height: "3.5vh",
		alignItems: "center",
		background: "unset",
		outline: "none",
		display: "flex",
		"& .MuiSlider-thumb": {
			color: "#FFFFFF",
			boxShadow: " 0px 4px 8px rgba(44, 39, 56, 0.2)",
			width: "18px",
			height: "18px",
			marginTop: "-8px",
		},
		"& .MuiSlider-rail": {
			color: "#D9D9D9",
		},
		"& .MuiSlider-track": {
			color: "#8800FF",
		},
	},
	menuItemRoot: {
		padding: "18px 20px",
		fontSize: 14,
		color: "#5B5B5B",
		lineHeight: "12px",
	},
	disabledButton: {
		"&.MuiButton-contained.Mui-disabled": {
			backgroundColor: "#979797",
			color: "#FFFFFF",
		},
	},
	badge: {
		"& .MuiBadge-badge": {
			top: 8,
			right: 17,
		},
	},
	sliderStyle: {
		"&.Mui-disabled .MuiSlider-track": {
			color: "#bdbdbd !important",
		},
	},
	selectedMenuItem: {
		backgroundColor: "#F1EDF7 !important",
	},
	menuStyle: {
		"&.MuiPopover-paper ": {
			maxWidth: "unset !important",
			width: "228px !important",
			// top: "22vh !important",
		},
	},
	dropDownText: {
		verticalAlign: "super",
		color: "#1A0638",
		textTransform: "none",
		fontSize: 16,
		fontWeight: 600,
		marginRight: 14,
	},
	buttonLoader: {
		width: "20px !important",
		height: "20px !important",
		marginLeft: "10px",
	},
	useInCampaignButton: {
		width: 200,
		borderRadius: 8,
		"&:hover": {
			backgroundColor: "#E7E7E7",
			opacity: 1,
		},
		"&:focus": {
			backgroundColor: "#F1EDF7",
		},
	},
	increaseResSelect: {
		borderRadius: 8,
		width: 228,
		height: 40,
		"&:hover": {
			backgroundColor: "#E7E7E7",
			opacity: 1,
		},
		"&:focus": {
			backgroundColor: "#F1EDF7",
		},
	},
	selectedDropDown: {
		backgroundColor: "#F1EDF7",
	},
	increaseResItem: {
		fontSize: 14,
	},
	downloadButtonContainer: {
		// marginTop: 12,
		// marginBottom: 12,
		// height: "12%",
		"& .MuiInputLabel-formControl": {
			top: "-2px",
		},
	},
	tooltip: {
		position: "absolute",
		bottom: -65,
		right: 0,
		borderRadius: 4,
		color: "#FFFFFF",
		backgroundColor: "#495057 !important",
		opacity: "0.7 !important",
		width: 330,
		height: 60,
		fontWeight: "normal",
		textTransform: "none !important" as any,
		zIndex: "1101 !important" as any,
		fontSize: "12px !important",
		padding: "12px !important",
		lineHeight: 1.25,
		"& .title + *": {
			marginTop: 7,
		},
	},
	customCursor: {
		width: (props) => props.brushConfig.lineWidth,
		height: (props) => props.brushConfig.lineWidth,
		position: "absolute",
		borderRadius: "50%",
		border: "none",
		zIndex: 100,
		transitionDuration: "100ms",
		transitionTimingFunction: "ease-out",
		pointerEvents: "none",
		backgroundColor: (props) => (props.isIframe ? theme.palette.primary.dark : props.brushConfig.strokeStyle),
		opacity: (props) => props.brushConfig.brushColorOpacity,
	},
}));

export default ToolsImage;
